class SignInPresenter {
  constructor(view, signInUseCase) {
    this.view = view;
    this.signInUseCase = signInUseCase;
    this.change = {};
  }

  onChange(value, field) {
    this.change[field] = value;
  }

  async submit() {
    try {
      const masterKey = this.view.getMasterKey();
      const user = {
        ...this.change,
        masterKey,
      };

      this.view.showProgress();

      const signedInUser = await this.signInUseCase.execute(user);
      console.log("sss", signedInUser);

      if (signedInUser.status === "Active") {
        switch (signedInUser.roles[0].id) {
          case "ENDORSER":
            this.view.navigateTo("/dashboard-endorser");
            break;
          case "MIS":
            this.view.navigateTo("/dashboard-admin");
            break;
          case "QA":
            this.view.navigateTo("/dashboard-QA");
            break;
          case "AGENTS":
            this.view.navigateTo("/dashboard-agent");
          default:
            break;
        }
      } else {
        this.view.hideProgress();

        this.view.showError(
          "Unable to proceed. Your account has been deactivated."
        );
      }
    } catch (error) {
      this.view.hideProgress();
      this.view.showError(error);
    }
  }
}

export default SignInPresenter;
