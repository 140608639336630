import { Progress, dialog } from "nq-component";
import BaseListPresenter from "../../base/BaseListPresenter";
import { findObjectUseCase, upsertUseCase } from "../../usecases/object";
import classNames from "../../classNames";
import { restUseCase } from "../../usecases/rest";
import { logDOM } from "@testing-library/react";

class QualityAssurancePresenter extends BaseListPresenter {
  init() {
    const user = this.view.getCurrentUser();
    this.limit = 20;
    this.where = { createdBy: user.id };
    this.search = {};
    this.filter = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  async getObjects() {
    this.reset();
    await this.countObjects();
    await this.findObjects();
    // await this.findReport();
  }

  componentDidMount() {
    this.init();
    return this.getObjects();
  }
  onClickAdd() {
    this.view.navigateTo("/endorsement-form");
  }

  onClickItem(index) {
    const object = this.objects[index];
    this.view.navigateTo("/endorsement-form/" + object.id);
  }

  onClickView(index, field) {
    const object = this.objects[index];
    this.view.navigateTo("/endorsement-form-view/" + object.id);
  }

  onClickItemAudit(index, field) {
    const object = this.objects[index];
    console.log("get object", object);

    switch (object.rType) {
      case "EMPLOYMENT VERIFICATION (EVR)":
        this.view.navigateTo(
          "/audit-form-qa-2/" +
            object.id +
            "/" +
            index +
            "/" +
            object.auditId +
            "/" +
            object.qaFormId
        );
        break;
      case "BUSINESS VERIFICATION (BVR)":
        this.view.navigateTo(
          "/audit-form-qa/" +
            object.id +
            "/" +
            index +
            "/" +
            object.auditId +
            "/" +
            object.qaFormId
        );
        break;
      case "RESIDENCE VERIFICATION (RVR)":
        this.view.navigateTo(
          "/audit-form-qa-3/" +
            object.id +
            "/" +
            index +
            "/" +
            object.auditId +
            "/" +
            object.qaFormId
        );
        break;

      default:
        break;
    }
  }

  onClickComparison(index, field) {
    const object = this.objects[index];
    console.log("get object", object);

    switch (object.rType) {
      case "EMPLOYMENT VERIFICATION (EVR)":
        this.view.navigateTo(
          "/comparison-evr/" +
            object.id +
            "/" +
            index +
            "/" +
            object.qaFormId +
            "/" +
            object.auditId
        );
        break;
      case "BUSINESS VERIFICATION (BVR)":
        this.view.navigateTo(
          "/comparison-bvr/" +
            object.id +
            "/" +
            index +
            "/" +
            object.qaFormId +
            "/" +
            object.auditId
        );
        break;
      case "RESIDENCE VERIFICATION (RVR)":
        this.view.navigateTo(
          "/comparison-rvr/" +
            object.id +
            "/" +
            index +
            "/" +
            object.qaFormId +
            "/" +
            object.auditId
        );
        break;

      default:
        break;
    }
  }

  async onClickItemSend(index) {
    const object = this.objects[index];
    console.log("send", object);

    let options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "Asia/Manila",
    };
    const formatter = new Intl.DateTimeFormat("en-GB", options);
    let partss = formatter.formatToParts(new Date());
    const timeString = `${partss.find((p) => p.type === "year").value}-${
      partss.find((p) => p.type === "month").value
    }-${partss.find((p) => p.type === "day").value} ${
      partss.find((p) => p.type === "hour").value
    }:${partss.find((p) => p.type === "minute").value}:${
      partss.find((p) => p.type === "second").value
    }`;

    object.status = "Done";
    object.statusQA = "Done";
    object.timeline = object?.timeline || [];
    object.timeline?.push({
      time: timeString,
      status: "Done",
    });

    const newObject = await upsertUseCase().execute("endorsement", object);

    this.objects[index] = object;

    this.view.setObjects(this.objects);
    dialog.close();
    dialog.fire({
      html: (
        <div className="text-end">
          <div className="text-center p-4">
            <i
              className={classNames("bi bi-file-earmark-check", "text-warning")}
              style={{ fontSize: "5rem" }}
            ></i>
            <h4 className="fw-bold">Success!</h4>
            <p className="m-0">Email Successfully Sent!</p>
            <button
              className="btn mb-2 mt-2 me-2"
              style={{ backgroundColor: "#EBBD2F" }}
              onClick={() => dialog.close()}
            >
              Confirm
            </button>
          </div>
        </div>
      ),
      footer: false,
    });
  }

  onClickExport() {
    this.view.exportPDF();
  }

  // async findReport(index) {
  //   const query = {
  //     where: {
  //       id: this.objects[index]?.auditId,
  //     },
  //     include: [
  //       "categories",
  //       "categories.text",
  //       "categories.text.answers",
  //       "categories.text.answers.answer",
  //       "upload",
  //     ],
  //   };
  //   const queryBvr = {
  //     where: {
  //       id: this.objects[index]?.auditId,
  //     },
  //     include: [
  //       "client",
  //       "categories",
  //       "categories.text",
  //       "categories.text.text",
  //       "questions",
  //       "questions.question_per_category",
  //       "questions.question_per_category.perCategory",
  //       "questions.question_per_category.choices",
  //       "upload",
  //     ],
  //   };

  //   let report;
  //   const rType = this.objects[0]?.rType;

  //   if (rType === "BUSINESS VERIFICATION (BVR)") {
  //     report = await findObjectUseCase().execute(
  //       "business_verification_form",
  //       queryBvr
  //     );
  //   } else if (rType === "EMPLOYMENT VERIFICATION (EVR)") {
  //     report = await findObjectUseCase().execute(
  //       "employment_verification_form",
  //       query
  //     );
  //   } else if (rType === "RESIDENCE VERIFICATION (RVR)") {
  //     report = await findObjectUseCase().execute(
  //       "residence_verification_form",
  //       query
  //     );
  //   }
  //   this.view.setState({ report });
  // }

  async findReport(index) {
    console.log("indexssss", index);

    console.log("this.objects[index]?.qaFormId", this.objects[index]?.qaFormId);
    const query = {
      where: {
        id: this.objects[index]?.qaFormId,
      },
      include: ["answers", "answer.answer", "answers.question"],
    };

    const query1 = {
      where: {
        id: this.objects[index]?.qaFormId,
      },
      include: [
        "answers",
        "answers.choices",
        "answers.question",
        "answers.question.choices",
        "answers.question.question",
      ],
    };

    let report;
    let questions;
    let labelQuestion;
    const rType = await this.objects[index || 0]?.rType;
    // console.log("TTYYY: ", rType);
    // console.log("QUESTTTT: ", questions);
    if (rType === "BUSINESS VERIFICATION (BVR)") {
      report = await findObjectUseCase().execute("bvr_forms_answer_qa", query1);
      const filterYesNo = report[0]?.answers?.filter(
        (item) => item.answers === "Yes" || item.answers === "No"
      );
      const tempResults = [];
      if (filterYesNo.length > 0) {
        for (const filter of filterYesNo) {
          const query = {
            where: {
              id: filter.question.id,
            },
          };
          const result = await findObjectUseCase().execute(
            "bvr_forms_categories_mis",
            query
          );
          const label = await findObjectUseCase().execute(
            "bvr_forms_categories_mis",
            { where: { questionType: "LABEL" } }
          );
          labelQuestion = label;
          const resultObj = result.find((item) => item);
          if (resultObj) {
            tempResults.push(resultObj);
          }
          questions = tempResults;
          // console.log("QUESATT: ", questions);
        }
      }
    } else if (rType === "EMPLOYMENT VERIFICATION (EVR)") {
      report = await findObjectUseCase().execute("evr_forms_answer_qa", query);
      console.log("REP: ", report);
    } else if (rType === "RESIDENCE VERIFICATION (RVR)") {
      report = await findObjectUseCase().execute("rvr_forms_answer_qa", query);
      console.log("REPsssssss: ", report);
    }
    console.log("BEFORE VIEW: ", report);
    this.view.setState({ report, index, questions, labelQuestion });
  }
  async sendEmail(index, url) {
    const user = this.view.getCurrentUser();
    // this.view.load();
    const object = this.objects[index];
    console.log("send update", object);
    const query = {
      where: {
        name: object?.clientName,
      },
    };

    const client = await findObjectUseCase().execute("users", query);
    console.log("cli", client);
    const method = "POST";
    const path = "/send-email/a7203911-37e2-462b-952e-95731c7530a5";
    const obj = {
      to: client[0].email,
      body: this.view.state.linkUrl,
      link: this.view.state.linkUrl,
      resident: object?.residentName,

      subject: object?.residentName,
    };

    try {
      await restUseCase().execute(method, path, {
        body: obj,
      });

      let options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
        timeZone: "Asia/Manila",
      };
      const formatter = new Intl.DateTimeFormat("en-GB", options);
      let partss = formatter.formatToParts(new Date());
      const timeString = `${partss.find((p) => p.type === "year").value}-${
        partss.find((p) => p.type === "month").value
      }-${partss.find((p) => p.type === "day").value} ${
        partss.find((p) => p.type === "hour").value
      }:${partss.find((p) => p.type === "minute").value}:${
        partss.find((p) => p.type === "second").value
      }`;

      object.status = "Done";
      object.statusQA = "Done";
      object.timeline = object?.timeline || [];
      object.timeline?.push({
        time: timeString,
        status: "Done",
      });
      object.qaOfficer = user?.name;

      const newObject = await upsertUseCase().execute("endorsement", object);

      this.objects[index] = object;

      this.view.setObjects(this.objects);
      dialog.close();

      dialog.fire({
        html: (
          <div className="text-end">
            <div className="text-center p-4">
              <i
                className={classNames(
                  "bi bi-file-earmark-check",
                  "text-warning"
                )}
                style={{ fontSize: "5rem" }}
              ></i>
              <h4 className="fw-bold">Success!</h4>
              <p className="m-0">Email Successfully Sent!</p>
              <button
                className="btn mb-2 mt-2 me-2"
                style={{ backgroundColor: "#EBBD2F" }}
                onClick={() => dialog.close()}
              >
                Confirm
              </button>
            </div>
          </div>
        ),
        footer: false,
      });
    } catch (error) {
      dialog.fire({
        html: (
          <div className="text-end">
            <div className="text-center p-4">
              <Progress />;
            </div>
          </div>
        ),
        footer: false,
      });
    }
  }

  onClickDownload(index) {
    // this.view.exportPDF();
    this.findReport(index).then(() => {
      this.view.exportPDF();
    });
  }

  async findReport(index) {
    const query = {
      where: {
        id: this.objects[index]?.qaFormId,
      },
      include: ["answers", "answer.answer", "answers.question"],
    };

    const query1 = {
      where: {
        id: this.objects[index]?.qaFormId,
      },
      include: [
        "answers",
        "answers.choices",
        "answers.question",
        "answers.question.choices",
        "answers.question.question",
      ],
    };

    let report;
    let questions;
    let labelQuestion;
    const rType = await this.objects[index || 0]?.rType;
    // console.log("TTYYY: ", rType);
    // console.log("QUESTTTT: ", questions);
    if (rType === "BUSINESS VERIFICATION (BVR)") {
      report = await findObjectUseCase().execute("bvr_forms_answer_qa", query1);
      const filterYesNo = report[0]?.answers?.filter(
        (item) => item.answers === "Yes" || item.answers === "No"
      );
      const tempResults = [];
      if (filterYesNo.length > 0) {
        for (const filter of filterYesNo) {
          const query = {
            where: {
              id: filter.question.id,
            },
          };
          const result = await findObjectUseCase().execute(
            "bvr_forms_categories_mis",
            query
          );
          const label = await findObjectUseCase().execute(
            "bvr_forms_categories_mis",
            { where: { questionType: "LABEL" } }
          );
          labelQuestion = label;
          const resultObj = result.find((item) => item);
          if (resultObj) {
            tempResults.push(resultObj);
          }
          questions = tempResults;
          // console.log("QUESATT: ", questions);
        }
      }
    } else if (rType === "EMPLOYMENT VERIFICATION (EVR)") {
      report = await findObjectUseCase().execute("evr_forms_answer_qa", query);
      console.log("REP: ", report);
    } else if (rType === "RESIDENCE VERIFICATION (RVR)") {
      report = await findObjectUseCase().execute("rvr_forms_answer_qa", query);
    }
    this.view.setState({ report, index, questions, labelQuestion });
  }
}

export default QualityAssurancePresenter;
