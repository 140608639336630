import React from "react";

function TableQA({ objects }) {
  const headerStyle = {
    backgroundColor: "black",
    color: "white",
  };

  const countStatus = (endorsements, status) => {
    return endorsements?.filter(
      (endorsement) => endorsement.statusQA === status
    ).length;
  };

  return (
    <div className="container mt-3">
      <div className="d-flex justify-content-between align-items-center">
        <h6 className="fw-bold">Quality Assurance</h6>

        {/* <div className="mb-2">
          <i class="bi bi-box-arrow-left" style={{ color: "#fdcb5b" }}></i>
          <span
            className="ms-1"
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              color: "#fdcb5b",
            }}
          >
            Export to CSV
          </span>
        </div> */}
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th style={headerStyle}>Name</th>
            <th style={headerStyle}>Pending</th>
            <th style={headerStyle}>Completed</th>
          </tr>
        </thead>
        <tbody>
          {objects?.map((user) => (
            <tr key={user.id}>
              <td>{`${user?.firstName} ${user?.lastName}`}</td>
              <td>{countStatus(user.endorsement, "Pending")}</td>
              <td>{countStatus(user.endorsement, "Done")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableQA;
