// import React from "react";
// import { InputFactory, InputJson, dialog } from "nq-component";
// import {
//   findObjectUseCase,
//   getObjectUseCase,
//   upsertUseCase,
// } from "../../usecases/object";
// import withRouter from "../../withRouter";
// import BaseFormPage from "../../base/BaseFormPage";
// import NavBar from "../../components/navbar";
// import FormFactory from "../../components/FormFactory";
// // import Form1 from "./components/Form1";
// import QAForm2Presenter from "./QAForm2Presenter";
// import Form2 from "../agent/components/Form2";
// import InputAdd from "../../components/InputAdd";

// class QAForm3Page extends BaseFormPage {
//   constructor(props) {
//     super(props);
//     this.state = {
//       object: {},
//       advanced: false,
//       type: [],
//       selectedForm: null,
//       reason: "",
//       uploadRelation: [],
//     };
//     this.presenter = new QAForm2Presenter(
//       this,
//       getObjectUseCase(),
//       upsertUseCase()
//     );
//   }

//   componentDidMount() {
//     this.presenter.componentDidMount();
//   }

//   getObjectId() {
//     return this.props.params && this.props.params.id;
//   }

//   getCollectionName() {
//     return "employment_verification_form_agent";
//   }

//   onClickAdvance() {
//     this.setState({ advanced: !this.state.advanced });
//   }

//   onChangeObject(object) {
//     this.presenter.onChangeObject(object);
//   }

//   // handleInputChange = (categoryId, value) => {
//   //   const newObject = { ...this.state.object };
//   //   const categoryIndex = newObject.categories.findIndex(
//   //     (c) => c.id === categoryId
//   //   );
//   //   if (categoryIndex !== -1) {
//   //     newObject.categories[categoryIndex].answer = value; // Assuming you want to store the input in 'answer' property
//   //   }
//   //   this.setState({ object: newObject });
//   // };

//   // handleInputChange = (id, value) => {
//   //   console.log("valval", value);
//   //   const newObject = { ...this.state.object };
//   //   // Check if the ID is from categories
//   //   const categoryIndex = newObject.categories.findIndex((c) => c.id === id);
//   //   if (categoryIndex !== -1) {
//   //     newObject.categories[categoryIndex].answer = value;
//   //   } else {
//   //     // If not found in categories, it must be in questions
//   //     newObject.questions.forEach((question) => {
//   //       question.question_per_category.forEach((perCategory) => {
//   //         if (perCategory.id === id) {
//   //           perCategory.answerQuestion = value; // Update specifically the answerQuestion field
//   //         }
//   //       });
//   //     });
//   //   }
//   //   this.setState({ object: newObject });
//   // };

//   handleInputChange = (id, value, choiceId = null) => {
//     console.log("Input Change", value);
//     const newObject = { ...this.state.object };

//     // Check if the ID is from text items
//     const textIndex = newObject.text.findIndex((t) => t.id === id);
//     if (textIndex !== -1) {
//       newObject.text[textIndex].answer = value;
//     } else {
//       // Check if the ID is from upload items
//       const uploadIndex = newObject.upload.findIndex((u) => u.id === id);
//       if (uploadIndex !== -1) {
//         newObject.upload[uploadIndex].answer = value;
//       } else {
//         // Check if it's for a choice within a question
//         let updated = false;
//         newObject.questions.forEach((question) => {
//           question.question_per_category.forEach((perCategory) => {
//             if (choiceId) {
//               // Update the choice name if choiceId is provided
//               const choiceIndex = perCategory.choices.findIndex(
//                 (c) => c.id === choiceId
//               );
//               if (choiceIndex !== -1) {
//                 perCategory.choices[choiceIndex].name = value;
//                 updated = true;
//               }
//             } else if (perCategory.id === id) {
//               // Update the answerQuestion if no choiceId is provided
//               perCategory.answerQuestion = value;
//               updated = true;
//             }
//           });
//         });
//         if (!updated) {
//           console.log("No matching IDs found for update.");
//         }
//       }
//     }
//     this.setState({ object: newObject });
//   };

//   onSubmitForm(e) {
//     e.preventDefault();

//     this.presenter.submit();
//   }

//   onClickStatusQA(index) {
//     this.presenter.onClickStatus(index);
//     dialog.close();
//   }

//   onChangeReason(e) {
//     const value = e.target.value;
//     console.log("val", value);
//     this.setState({ reason: value });
//   }

//   onClickStatusQADis() {
//     dialog.fire({
//       html: (
//         <>
//           <div>
//             <h5 className="text-danger">Disapprove Audit</h5>
//             <div className="col">
//               <h6>Please enter reason of disapproval</h6>
//               <input
//                 className="form-control"
//                 type="text"
//                 onChange={(e) => this.onChangeReason(e)}
//               />
//             </div>
//             <div className="m-3 text-center">
//               <button
//                 className="btn btn-light me-2"
//                 onClick={() => dialog.close()}
//               >
//                 Cancel
//               </button>
//               <button
//                 className="btn btn-danger text-white"
//                 onClick={() => this.onClickStatusQA("Disapprove")}
//               >
//                 Disapprove
//               </button>
//             </div>
//           </div>
//         </>
//       ),
//       footer: false,
//     });
//   }

//   handleImagesChange = (items) => {
//     this.setState((prevState) => ({
//       object: {
//         ...prevState.object,
//         uploadRelation: items,
//       },
//     }));
//   };

//   onChange(value, field) {
//     const newObject = { ...this.state.object }; // Create a copy of the current object
//     newObject[field] = value; // Update the specific field with the new value
//     this.setState({ object: newObject }); // Update the state with the modified object
//   }

//   render() {
//     const object = this.state.object;
//     console.log("object", object);
//     const advanced = this.state.advanced;
//     const schema = this.getSchema(this.getCollectionName());
//     const user = this.getCurrentUser();
//     if (!schema) return <h1>no schema</h1>;
//     const label = this.getObjectId() === undefined ? "Add New " : "View ";
//     return (
//       <>
//         <NavBar
//           action={() => (
//             <div className="dropdown dropstart d-inline-block">
//               <i
//                 role="button"
//                 data-bs-toggle="dropdown"
//                 className="bi bi-three-dots-vertical"
//               ></i>
//               <div className="dropdown-menu fs-xs">
//                 <button
//                   onClick={this.onClickAdvance.bind(this)}
//                   className="dropdown-item py-3"
//                 >
//                   <i className="bi bi-gear pe-2" />
//                   Toggle Advance
//                 </button>
//               </div>
//             </div>
//           )}
//         />
//         <div className="overflow-auto">
//           <div className="h-100">
//             <div className="p-3 p-lg-4">
//               <div className="row">
//                 <div className="col">
//                   <h1 className="fw-bold mt-3 text-capitalize">
//                     {label + (schema.label || this.getCollectionName())}
//                   </h1>
//                 </div>
//                 {user.roles[0].name === "QA" ? (
//                   <div className="mt-3 col text-end">
//                     <button
//                       className="btn text-white me-2"
//                       style={{ backgroundColor: "#04376a" }}
//                       onClick={() => this.onClickStatusQA("Approved")}
//                     >
//                       APPROVE
//                     </button>
//                     <button
//                       className="btn text-white"
//                       style={{ backgroundColor: "#fdcb5b" }}
//                       onClick={() => this.onClickStatusQADis()}
//                     >
//                       DISAPPROVE
//                     </button>
//                   </div>
//                 ) : null}
//               </div>
//               <div className="d-flex">
//                 <ul className="nav nav-tabs mb-3">
//                   <li className="nav-item">
//                     <a
//                       className="nav-link active"
//                       id="credits-tab"
//                       data-bs-toggle="tab"
//                       href="#credits"
//                       role="tab"
//                       aria-controls="credits"
//                       aria-selected="true"
//                     >
//                       Overview
//                     </a>
//                   </li>
//                   <li className="nav-item">
//                     <a
//                       className="nav-link"
//                       id="logs-tab"
//                       data-bs-toggle="tab"
//                       href="#logs"
//                       role="tab"
//                       aria-controls="logs"
//                       aria-selected="false"
//                     >
//                       Edit Details
//                     </a>
//                   </li>
//                 </ul>
//               </div>
//               <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
//                 <div className="tab-content">
//                   <div
//                     className="tab-pane fade show active"
//                     id="credits"
//                     role="tabpanel"
//                     aria-labelledby="credits-tab"
//                   >
//                     <div className="row g-3">
//                       <Form2
//                         object={object}
//                         onChange={this.handleInputChange}
//                         disabled={true}
//                       />
//                       <div>
//                         <label className="mb-2">Remarks</label>
//                         <InputFactory
//                           type="String"
//                           _type="Text"
//                           field="remarks"
//                           object={object}
//                           onChange={this.onChange.bind(this)}
//                         />
//                       </div>
//                       <InputAdd
//                         object={object}
//                         onImagesChange={this.handleImagesChange}
//                         user={user}
//                       />
//                     </div>
//                   </div>
//                   <div
//                     className="tab-pane fade show"
//                     id="logs"
//                     role="tabpanel"
//                     aria-labelledby="logs-tab"
//                   >
//                     <form onSubmit={this.onSubmitForm.bind(this)}>
//                       <div className="row g-3">
//                         <Form2
//                           object={object}
//                           onChange={this.handleInputChange}
//                         />
//                         <div>
//                           <label className="mb-2">Remarks</label>
//                           <InputFactory
//                             type="String"
//                             _type="Text"
//                             field="remarks"
//                             object={object}
//                             onChange={this.onChange.bind(this)}
//                           />
//                         </div>
//                         <InputAdd
//                           object={object}
//                           onImagesChange={this.handleImagesChange}
//                           user={user}
//                         />
//                       </div>
//                       <div className="mt-4">
//                         <button
//                           type="submit"
//                           className="btn btn-primary fs-sm me-3"
//                         >
//                           <i className="bi bi-file-earmark-check me-2"></i>SAVE
//                         </button>
//                         <button
//                           type="button"
//                           className="btn btn-light fs-sm"
//                           onClick={this.onClickBack.bind(this)}
//                         >
//                           GO BACK
//                         </button>
//                       </div>
//                     </form>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </>
//     );
//   }
// }

// export default withRouter(QAForm3Page);

import React from "react";
import { InputFactory, InputJson, dialog } from "nq-component";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import withRouter from "../../withRouter";
import BaseFormPage from "../../base/BaseFormPage";
import NavBar from "../../components/navbar";
import FormFactory from "../../components/FormFactory";
// import Form1 from "./components/Form1";
import QAForm2Presenter from "./QAForm2Presenter";
import Form2 from "./components/Form2";
import InputAdd from "../../components/InputAdd";
import Form3 from "./components/Form3";
import QAForm3Presenter from "./QAForm3Presenter";
// import Form2 from "../agent/components/Form2";

class QAForm2Page extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      activeTab: "overview",
      answer: null,
      advanced: false,
      type: [],
      selectedForm: null,
      reason: "",
      uploadRelation: [],
    };
    this.presenter = new QAForm3Presenter(
      this,
      getObjectUseCase(),
      upsertUseCase()
    );
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  getObjectId() {
    return this.props.params && this.props.params.id;
  }

  getAnswerId() {
    return this.props.params.formId;
  }

  setAnswer(answer) {
    this.setState({ answer });
  }

  getAnswer() {
    return this.state?.answer;
  }

  getCollectionName() {
    return "rvr_forms_mis";
  }

  onClickAdvance() {
    this.setState({ advanced: !this.state.advanced });
  }

  onChangeObject(object) {
    this.presenter.onChangeObject(object);
  }

  // handleInputChange = (categoryId, value) => {
  //   const newObject = { ...this.state.object };
  //   const categoryIndex = newObject.categories.findIndex(
  //     (c) => c.id === categoryId
  //   );
  //   if (categoryIndex !== -1) {
  //     newObject.categories[categoryIndex].answer = value; // Assuming you want to store the input in 'answer' property
  //   }
  //   this.setState({ object: newObject });
  // };

  // handleInputChange = (id, value) => {
  //   console.log("valval", value);
  //   const newObject = { ...this.state.object };
  //   // Check if the ID is from categories
  //   const categoryIndex = newObject.categories.findIndex((c) => c.id === id);
  //   if (categoryIndex !== -1) {
  //     newObject.categories[categoryIndex].answer = value;
  //   } else {
  //     // If not found in categories, it must be in questions
  //     newObject.questions.forEach((question) => {
  //       question.question_per_category.forEach((perCategory) => {
  //         if (perCategory.id === id) {
  //           perCategory.answerQuestion = value; // Update specifically the answerQuestion field
  //         }
  //       });
  //     });
  //   }
  //   this.setState({ object: newObject });
  // };

  // handleInputChange = (id, value, choiceId = null) => {
  //   console.log("Input Change", value);
  //   const newObject = { ...this.state.object };

  //   // Check if the ID is from text items
  //   const textIndex = newObject.text?.findIndex((t) => t.id === id);
  //   if (textIndex !== -1) {
  //     newObject?.text[textIndex].answer = value;
  //   } else {
  //     // Check if the ID is from upload items
  //     const uploadIndex = newObject.upload.findIndex((u) => u.id === id);
  //     if (uploadIndex !== -1) {
  //       newObject.upload[uploadIndex].answer = value;
  //     } else {
  //       // Check if it's for a choice within a question
  //       let updated = false;
  //       newObject.questions.forEach((question) => {
  //         question.question_per_category.forEach((perCategory) => {
  //           if (choiceId) {
  //             // Update the choice name if choiceId is provided
  //             const choiceIndex = perCategory.choices.findIndex(
  //               (c) => c.id === choiceId
  //             );
  //             if (choiceIndex !== -1) {
  //               perCategory.choices[choiceIndex].name = value;
  //               updated = true;
  //             }
  //           } else if (perCategory.id === id) {
  //             // Update the answerQuestion if no choiceId is provided
  //             perCategory.answerQuestion = value;
  //             updated = true;
  //           }
  //         });
  //       });
  //       if (!updated) {
  //         console.log("No matching IDs found for update.");
  //       }
  //     }
  //   }
  //   this.setState({ object: newObject });
  // };
  // handleInputChange = (categoryId, answerId, value) => {
  //   console.log("Input Change:", categoryId, answerId, value);
  //   const newObject = { ...this.state.object };
  //   const newValidationErrors = { ...this.state.validationErrors };

  //   // Clear validation error when the user starts typing
  //   if (
  //     newValidationErrors[categoryId] &&
  //     newValidationErrors[categoryId][answerId]
  //   ) {
  //     newValidationErrors[categoryId][answerId] = false;
  //   }

  //   newObject.categories = newObject.categories.map((category) => {
  //     if (category.id === categoryId) {
  //       return {
  //         ...category,
  //         text: category.text.map((textItem) => {
  //           return {
  //             ...textItem,
  //             answers: textItem.answers.map((answerItem) => {
  //               if (answerItem.id === answerId) {
  //                 return { ...answerItem, answer: value };
  //               }
  //               return answerItem;
  //             }),
  //           };
  //         }),
  //       };
  //     }
  //     return category;
  //   });

  //   this.setState({ object: newObject, validationErrors: newValidationErrors });
  // };
  // handleInputChange = (categoryId, questionId, value) => {
  //   this.setState((prevState) => {
  //     const existingAnswerIndex = prevState.answer.answers.findIndex(
  //       (ans) => ans.question.id === questionId
  //     );

  //     let updatedAnswers;

  //     if (existingAnswerIndex > -1) {
  //       // Update existing answer
  //       updatedAnswers = prevState.answer.answers.map((ans, index) =>
  //         index === existingAnswerIndex ? { ...ans, answer: value } : ans
  //       );
  //     } else {
  //       // Add new answer
  //       updatedAnswers = [
  //         ...prevState.answer.answers,
  //         {
  //           question: { id: questionId },
  //           answer: value,
  //         },
  //       ];
  //     }

  //     console.log("chnage to qa", updatedAnswers);

  //     return {
  //       answer: {
  //         ...prevState.answer,
  //         answers: updatedAnswers,
  //       },
  //     };
  //   });
  // };
  handleInputChange = (categoryId, questionId, value) => {
    this.setState((prevState) => {
      const existingAnswerIndex = prevState?.answer?.answers.findIndex(
        (ans) => ans.question.id === questionId
      );

      let updatedAnswers;

      if (existingAnswerIndex > -1) {
        // Update existing answer
        updatedAnswers = prevState.answer.answers.map((ans, index) =>
          index === existingAnswerIndex ? { ...ans, answer: value } : ans
        );
      } else {
        // Add new answer
        updatedAnswers = [
          ...prevState.answer.answers,
          {
            question: { id: questionId },
            answer: value,
          },
        ];
      }

      return {
        answer: {
          ...prevState.answer,
          answers: updatedAnswers,
        },
      };
    });
  };

  onSubmitForm(e) {
    e.preventDefault();

    this.presenter.submit();
  }

  onClickStatusQA(index) {
    this.presenter.onClickStatus(index);
    dialog.close();
  }

  onChangeReason(e) {
    const value = e.target.value;
    console.log("val", value);
    this.setState({ reason: value });
  }

  onClickStatusQADis() {
    dialog.fire({
      html: (
        <>
          <div>
            <h5 className="text-danger">Disapprove Audit</h5>
            <div className="col">
              <h6>Please enter reason of disapproval</h6>
              <input
                className="form-control"
                type="text"
                onChange={(e) => this.onChangeReason(e)}
              />
            </div>
            <div className="m-3 text-center">
              <button
                className="btn btn-light me-2"
                onClick={() => dialog.close()}
              >
                Cancel
              </button>
              <button
                className="btn btn-danger text-white"
                onClick={() => this.onClickStatusQA("Disapprove")}
              >
                Disapprove
              </button>
            </div>
          </div>
        </>
      ),
      footer: false,
    });
  }

  // handleImagesChange = (items) => {
  //   this.setState((prevState) => ({
  //     object: {
  //       ...prevState.object,
  //       uploadRelation: items,
  //     },
  //   }));
  // };
  handleImagesChange = (items) => {
    this.setState((prevState) => ({
      answer: {
        ...prevState.answer,
        uploadRelation: prevState.answer?.uploadRelation?.map(
          (relation, index) => {
            // Check if the image from the updated 'items' array is not empty
            const updatedItem = items[index];
            return {
              ...relation,
              image: updatedItem.image || relation.image, // Retain old image if the new one is empty
            };
          }
        ),
      },
    }));
  };

  onChange(value, field) {
    const newObject = { ...this.state.object }; // Create a copy of the current object
    newObject[field] = value; // Update the specific field with the new value
    this.setState({ object: newObject }); // Update the state with the modified object
  }

  handleTabChange(tabName) {
    this.setState({ activeTab: tabName });
  }

  render() {
    const object = this.state.object;
    const activeTab = this.state.activeTab;
    const answer = this.state.answer;
    console.log("object", object);
    console.log("answer send render", answer);
    const advanced = this.state.advanced;
    const schema = this.getSchema(this.getCollectionName());
    const user = this.getCurrentUser();
    if (!schema) return <h1>no schema</h1>;
    const label = this.getObjectId() === undefined ? "Add New " : "View ";
    return (
      <>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />
        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <div className="row">
                <div className="col">
                  <h1 className="fw-bold mt-3 text-capitalize">
                    {label + (schema.label || this.getCollectionName())}
                  </h1>
                </div>
                {user.roles[0].id === "QA" ? (
                  <div>
                    {activeTab === "overview" && (
                      <div className="mt-3 col text-end">
                        <button
                          className="btn text-white me-2"
                          style={{ backgroundColor: "#04376a" }}
                          onClick={() => this.onClickStatusQA("Approved")}
                        >
                          APPROVE
                        </button>
                        <button
                          className="btn text-white"
                          style={{ backgroundColor: "#fdcb5b" }}
                          onClick={() => this.onClickStatusQADis()}
                        >
                          DISAPPROVE
                        </button>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
              <div className="d-flex">
                <ul className="nav nav-tabs mb-3">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="credits-tab"
                      data-bs-toggle="tab"
                      href="#credits"
                      role="tab"
                      aria-controls="credits"
                      aria-selected="true"
                      onClick={() => this.handleTabChange("overview")}
                    >
                      Overview
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="logs-tab"
                      data-bs-toggle="tab"
                      href="#logs"
                      role="tab"
                      aria-controls="logs"
                      aria-selected="false"
                      onClick={() => this.handleTabChange("logs")}
                    >
                      Edit Details
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="credits"
                    role="tabpanel"
                    aria-labelledby="credits-tab"
                  >
                    <div className="row g-3">
                      <div>
                        <label className="mb-1">Account Name</label>
                        <InputFactory
                          type="String"
                          field="accountName"
                          object={answer}
                          onChange={this.onChange.bind(this)}
                          required
                          disabled={true}
                        />
                      </div>
                      <Form3
                        object={object}
                        answer={answer}
                        onChange={this.handleInputChange}
                        disabled={true}
                      />
                      <div>
                        <label className="mb-2">Remarks 1</label>
                        <InputFactory
                          type="String"
                          _type="Text"
                          field="remarks"
                          object={answer}
                          onChange={this.onChange.bind(this)}
                          disabled={true}
                        />
                      </div>
                      <div>
                        <label className="mb-2">Remarks 2</label>
                        <InputFactory
                          type="String"
                          _type="Text"
                          field="remarks2"
                          object={answer}
                          onChange={this.onChange.bind(this)}
                          disabled={true}
                        />
                      </div>
                      <InputAdd
                        object={object}
                        onImagesChange={this.handleImagesChange}
                        user={user}
                      />
                    </div>
                  </div>
                  <div
                    className="tab-pane fade show"
                    id="logs"
                    role="tabpanel"
                    aria-labelledby="logs-tab"
                  >
                    <form onSubmit={this.onSubmitForm.bind(this)}>
                      <div className="row g-3">
                        <div>
                          <label className="mb-1">Account Name</label>
                          <InputFactory
                            type="String"
                            field="accountName"
                            object={answer}
                            onChange={this.onChange.bind(this)}
                            required
                          />
                        </div>
                        <Form3
                          object={object}
                          answer={this.state?.answer}
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div>
                        <label className="mb-2">Remarks 1</label>
                        <InputFactory
                          type="String"
                          _type="Text"
                          field="remarks"
                          object={answer}
                          onChange={this.onChange.bind(this)}
                        />
                      </div>
                      <div>
                        <label className="mb-2">Remarks 2</label>
                        <InputFactory
                          type="String"
                          _type="Text"
                          field="remarks2"
                          object={answer}
                          onChange={this.onChange.bind(this)}
                        />
                      </div>
                      <InputAdd
                        object={answer}
                        onImagesChange={this.handleImagesChange}
                        user={user}
                      />
                      <div className="mt-4">
                        <button
                          type="submit"
                          className="btn btn-primary fs-sm me-3"
                        >
                          <i className="bi bi-file-earmark-check me-2"></i>SAVE
                        </button>
                        <button
                          type="button"
                          className="btn btn-light fs-sm"
                          onClick={this.onClickBack.bind(this)}
                        >
                          GO BACK
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(QAForm2Page);
