import BaseFormPresenter from "../../../base/BaseFormPresenter";
import { saveObjectUseCase, upsertUseCase } from "../../../usecases/object";

class BusinessAuditFormPresenter extends BaseFormPresenter {
  componentDidMount() {
    this.init();
    this.getObject();
  }
  async getObject() {
    const collection = this.view.getCollectionName();
    const id = this.object?.id || this.view.getObjectId();
    if (id) {
      const params = {
        // include: [
        //   "client",
        //   "categories",
        //   "categories.text",
        //   "categories.text.text",
        //   "questions",
        //   "questions.question_per_category",
        //   "questions.question_per_category.perCategory",
        //   "questions.question_per_category.choices",
        //   "upload",
        //   "uploadRelation",
        // ],
        include: [
          "selectedClient",

          "categories",
          "categories.questions",
          "categories.questions.choices",
          "uploadRelation",
        ],
      };
      try {
        this.view.showProgress();
        this.object = await this.getObjectUseCase.execute(collection, id, {
          params,
        });
        console.log("BRV STRUCTURE : ", this.object);
        this.view.hideProgress();
        this.view.setObject(this.object);
      } catch (error) {
        this.view.hideProgress();
        this.view.showError(error);
      }
    }
  }

  // onChange(value, field) {
  //   console.log("Val", value, "Field", field);
  //   console.log("chnage", (this.change[field] = value));
  //   this.change[field] = value;

  //   this.object[field] = value;
  //   this.view.setObject(this.object);
  // }
  init() {
    const collection = this.view.getCollectionName();
    const exam = this.view.getExamId();
    const query = {
      where: { id: exam?.id },
      include: [
        "categories",
        "categories.text",
        // "categories.questions.answers",
      ],
    };
    if (exam?.id) {
      this.view.showProgress();
      this.findObjectUseCase
        .execute("exams", query)
        .then(([object]) => {
          this.view.hideProgress();
          this.object = object;
          this.view.setObject(Object.assign({}, object));
        })
        .catch((error) => {
          this.view.hideProgress();
          this.view.showError(error);
        });
    }
  }

  submit() {
    const collection = this.view.getCollectionName();
    const object = this.view.getObject();
    // console.log("selectedClient", this.view.state.selectedClients);

    this.view.showProgress();
    if (object.id) {
      const change = object;
      change.id = this.object.id;
      upsertUseCase()
        .execute(collection, change)
        .then(() => {
          this.view.hideProgress();
          this.view.navigateBack();
        })
        .catch((error) => {
          this.view.hideProgress();
          this.view.showError(error);
        });
    } else {
      const roles = this.view.getCurrentRoles();
      const aclRoles = roles.map((r) => `role:${r.id}`);
      const user = this.view.getCurrentUser();
      const acl = {
        read: ["*", user.id, aclRoles],
        write: ["*", user.id, aclRoles],
      };
      object.acl = acl;
      // object.clients = this.view.state.selectedUser.label;
      // object.selectedClient = this.view.state.selectedClients;
      object.type = "BUSINESS VERIFICATION (BVR)";
      delete object.agent;
      delete object.selectedUser;
      console.log("send object", object);
      saveObjectUseCase()
        .execute(collection, object)
        .then(() => {
          this.view.hideProgress();
          this.view.navigateBack();
        })
        .catch((error) => {
          this.view.hideProgress();
          this.view.showError(error);
        });
    }
  }

  backClick() {
    // @todo check if object is un-save show discard
    this.view.navigateBack();
  }
}
export default BusinessAuditFormPresenter;
