import BaseFormPresenter from "../../base/BaseFormPresenter";
import { findObjectUseCase } from "../../usecases/object";

class EndorsementViewPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }

  async getObject() {
    const collection = this.view.getCollectionName();
    const id = this.object.id || this.view.getObjectId();
    if (id) {
      const params = { include: ["all"] };
      try {
        this.view.showProgress();
        this.object = await this.getObjectUseCase.execute(collection, id, {
          params,
        });
        this.view.hideProgress();
        this.view.setObject(this.object);
      } catch (error) {
        this.view.hideProgress();
        this.view.showError(error);
      }
    }
  }

  async save() {
    const collection = this.view.getCollectionName();
    const user = this.view.getCurrentUser();
    const currentDate = new Date().toISOString();
    const query = {
      sort: {
        createdAt: 1,
      },
      include: ["All", "roles.name", "location.name"],
    };

    if (this.object.id) {
      this.change.id = this.object.id;
    }

    try {
      // Fetch all users
      const users = await findObjectUseCase().execute("users", query);
      console.log("All users fetched:", users);

      // Filter users with the role MIS
      const MISUsers = users.filter((user) =>
        user.roles.some((role) => role.id === "MIS")
      );

      // Sort MISUsers by createdAt to establish initial order
      MISUsers.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      console.log("establish", MISUsers);

      // Sort users by the number of endorsements, then by createdAt if endorsements are equal
      const sortedMISUsers = MISUsers.sort((a, b) => {
        const aEndorsementCount = a.endorsement ? a.endorsement.length : 0;
        const bEndorsementCount = b.endorsement ? b.endorsement.length : 0;
        console.log("aE", aEndorsementCount);
        console.log("bE", bEndorsementCount);
        const check = aEndorsementCount === bEndorsementCount ? true : false;
        console.log("check", check);

        if (aEndorsementCount === bEndorsementCount) {
          return new Date(a.createdAt) - new Date(b.createdAt);
        }
        const minus = aEndorsementCount - bEndorsementCount;
        console.log("minus", minus);
        return minus;
      });
      console.log("sorted[0]", sortedMISUsers);
      console.log("sorted[0]", sortedMISUsers[0]);

      // Select the user with the fewest endorsements, or the earliest created if counts are equal
      const userForEndorsement = sortedMISUsers[0];
      console.log("Next user for endorsement:", userForEndorsement);

      // Process the endorsement
      this.change.createdBy = userForEndorsement.id;
      this.change.status = "For Dispatch";
      this.change.clientName = user.name;
      this.change.borrowerName = this.change.residentName;
      this.change.rType = this.view.state.selectedForm.label;
      const responseEndorse = await this.upsertUseCase.execute(
        collection,
        this.change
      );
      console.log("Endorsement response:", responseEndorse);

      // Update the endorsement array for the user
      userForEndorsement.endorsement = userForEndorsement.endorsement || [];
      userForEndorsement.endorsement.push({
        id: responseEndorse.id,
        createdAt: currentDate,
      });

      // Save the updated user
      await this.upsertUseCase.execute("users", userForEndorsement);

      this.change = {};
    } catch (error) {
      console.error("Error in save function:", error);
      throw error; // Rethrow the error to be caught by the caller
    }
  }
}

export default EndorsementViewPresenter;
