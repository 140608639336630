import React from "react";
import { InputJson } from "nq-component";
import { getObjectUseCase, upsertUseCase } from "../../usecases/object";
import withRouter from "../../withRouter";
import BaseFormPage from "../../base/BaseFormPage";
import NavBar from "../../components/navbar";
import FormFactory from "../../components/FormFactory";
import UserFormPresenter from "./UserFormPresenter";
import regionsPh from "./regionsPh.json";
import LocationSelectorMIS from "../../components/LocationSelectorMIS";
import InputFactory from "../../components/InputFactory";

class UserFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      advanced: false,
      selectedRegions: [],
      selectedProvinces: [],
      selectedCity: "",
      selectedBarangay: "",
      roleMIS: "",

      regionsList: [],
      provinceList: [],
      cityList: [],
      barangayList: [],
    };
    this.presenter = new UserFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase()
    );
  }

  getCollectionName() {
    return "users";
  }

  componentDidMount() {
    this.presenter.componentDidMount();

    const regionsList = regionsPh.map((region) => ({
      name: region.name,
      code: region.code,
    }));
    this.setState({ regionsList });
  }

  onClickAdvance() {
    this.setState({ advanced: !this.state.advanced });
  }

  onChangeObject(object) {
    this.presenter.onChangeObject(object);
  }

  getAcl() {
    const user = this.getCurrentUser();
    return {
      read: [
        "*",
        user.id,
        "role:MIS",
        "role:ENDORSER",
        "role:QA",
        "role:AGENTS",
      ],
      write: [
        "*",
        user.id,
        "role:MIS",
        "role:ENDORSER",
        "role:QA",
        "role:AGENTS",
      ],
    };
  }

  onChange(value, field) {
    console.log("v", value);
    if (field === "roles") {
      this.setState({ roleMIS: value[0]?.name });
    }

    this.presenter.onChange(value, field);
  }

  onSubmitForm(e) {
    e.preventDefault();
    console.log("PROVINCES STATE", this.state.selectedProvinces);
    console.log("REGIONS STATE", this.state.selectedRegions);
    this.presenter.submit();
  }

  handleLocationChange = (regions, provinces) => {
    console.log("RR", regions);
    this.setState({ selectedRegions: regions, selectedProvinces: provinces });
  };

  render() {
    const object = this.state.object;
    const cityList = this.state.cityList;
    console.log("ROEL ROLE", this.state?.roleMIS);
    const advanced = this.state.advanced;
    const schema = this.getSchema(this.getCollectionName());
    if (!schema) return <h1>no schema</h1>;
    const label = this.getObjectId() === undefined ? "Add New " : "Edit ";
    const excludeFields = object.id
      ? [
          // "password",
          "endorsement",
          "provinces",
          "regions",
          "status",
          "group",
          "location",
          "branchName",
        ]
      : [
          "endorse",
          "endorsement",
          "rolesName",
          "provinces",
          "regions",
          "status",
          "group",
          "location",
          "branchName",
        ];
    return (
      <>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />
        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <h1 className="fw-bold mt-3 text-capitalize">
                {label + (schema.label || this.getCollectionName())}
              </h1>
              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  <div className="row g-3">
                    {advanced ? (
                      <InputJson
                        defaultValue={object}
                        onChange={this.onChangeObject.bind(this)}
                        rows="15"
                      />
                    ) : (
                      <>
                        <FormFactory
                          schema={schema}
                          object={object}
                          onChange={this.onChange.bind(this)}
                          excludeFields={excludeFields}
                        />

                        {/* <LocationSelector /> */}
                        {object.rolesName === "MIS" && (
                          <LocationSelectorMIS
                            onLocationChange={this.handleLocationChange}
                            object={object}
                          />
                        )}
                        {this.state?.roleMIS === "MIS" && (
                          <LocationSelectorMIS
                            onLocationChange={this.handleLocationChange}
                            object={object}
                          />
                        )}
                        {(this.state?.roleMIS === "ENDORSER" ||
                          object.rolesName === "ENDORSER") && (
                          <div>
                            <label>Client</label>
                            <InputFactory
                              type="Pointer"
                              field="group"
                              target="group"
                              object={object}
                              onChange={this.onChange.bind(this)}
                              placeholder="Select Group"
                              required
                            />
                          </div>
                        )}

                        {(this.state?.roleMIS === "ENDORSER" ||
                          object.rolesName === "ENDORSER") && (
                          <div>
                            <label>Group Name</label>
                            <InputFactory
                              type="String"
                              field="branchName"
                              object={object}
                              onChange={this.onChange.bind(this)}
                              placeholder="e.g SBF"
                            />
                          </div>
                        )}

                        {(this.state?.roleMIS === "AGENTS" ||
                          object.rolesName === "AGENTS") && (
                          <div>
                            <label>Location</label>
                            <InputFactory
                              type="Pointer"
                              field="location"
                              target="location"
                              object={object}
                              onChange={this.onChange.bind(this)}
                              placeholder="Select Location"
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn text-dark fs-sm me-3"
                      style={{ backgroundColor: "#fdcb5b" }}
                    >
                      <i className="bi bi-file-earmark-check me-2"></i>SAVE
                    </button>
                    <button
                      type="button"
                      className="btn btn-light fs-sm"
                      onClick={this.onClickBack.bind(this)}
                    >
                      GO BACK
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(UserFormPage);
