// import React from "react";
// // import Checkbox from "../Checkbox";
// // import Accordion from "../Accordion";
// // import OutputFactory from "../OutputFactory";
// import CollapseView from "./CollapseView";
// // import AccordionProvider from "../Accordion/AccordionProvider";
// // import AccordionCollapse from "../Accordion/AccordionCollapse";
// import { Accordion, Checkbox, OutputFactory } from "nq-component";
// import AccordionCollapse from "nq-component/dist/Accordion/AccordionCollapse";
// import AccordionProvider from "nq-component/dist/Accordion/AccordionProvider";

// let timeoutId;

// function Body({
//   objects,
//   fields,
//   excludeFields,
//   selected,
//   selectable,
//   onSelect,
//   onClick,
//   onLongPress,
//   actionsList,
//   collapsable,
//   onCollapse,
//   toggleable,
//   actions,
//   onClickItem,
//   fieldImage,
// }) {
//   const onPointerDown = () => {
//     timeoutId = setTimeout(onLongPress, 500); // set long press time to 500ms
//   };

//   const onPointerUp = () => {
//     clearTimeout(timeoutId);
//   };
//   return objects.map((object, index) => {
//     const id = object.id || object._id;
//     const checked = selected.includes(object);
//     return (
//       <AccordionProvider key={id}>
//         <tr>
//           {selectable && (
//             <th className="align-middle">
//               <Checkbox
//                 checked={checked}
//                 id={"check_" + id}
//                 onChange={() => onSelect(index)}
//               />
//             </th>
//           )}

//           {/* <th className="align-middle">{object.roles[0].name}</th> */}

//           {Object.keys(fields).map((field, i) => {
//             const options = fields[field];
//             if (excludeFields.includes(field)) return null;
//             return (
//               <Accordion
//                 as="td"
//                 eventKey={`_${id}`}
//                 key={field}
//                 className="fs-sm text-truncate"
//                 onPointerDown={onPointerDown}
//                 onPointerUp={onPointerUp}
//                 // onClick={() => onClick(index, field)}
//                 style={{
//                   cursor: "pointer",
//                   maxWidth: "15em",
//                 }}
//               >
//                 <OutputFactory field={field} object={object} {...options} />
//               </Accordion>
//             );
//           })}
//           <td className="fs-sm text-truncate">{object.status}</td>
//           {actionsList.length > 0 && (
//             <td>
//               <div style={{ width: "50px" }}></div>
//             </td>
//           )}
//           {actionsList.map((action) => {
//             return (
//               <td className="text-truncate">
//                 <button
//                   onClick={action.onClick && action.onClick.bind(this, index)}
//                   className="btn btn-outline-primary btn-sm fs-xs shadow-none"
//                 >
//                   <i className={action.icon} /> {action.label}
//                 </button>
//               </td>
//             );
//           })}
//           <td>
//             <div>{object?.roles?.[0]?.name}</div>
//           </td>
//         </tr>
//         {collapsable && (
//           <tr>
//             <td className="p-0" colSpan={Object.keys(fields).length + 1}>
//               <AccordionCollapse
//                 toggleable={toggleable}
//                 className="collapse p-3 "
//                 eventKey={`_${id}`}
//               >
//                 {onCollapse(index, object, excludeFields) ||
//                   CollapseView(
//                     index,
//                     object,
//                     excludeFields,
//                     fields,
//                     actions,
//                     onClickItem,
//                     fieldImage
//                   )}
//               </AccordionCollapse>
//             </td>
//           </tr>
//         )}
//       </AccordionProvider>
//     );
//   });
// }

// export default Body;
import React from "react";
import CollapseView from "./CollapseView";
import { Accordion, Checkbox, OutputFactory } from "nq-component";
import AccordionCollapse from "nq-component/dist/Accordion/AccordionCollapse";
import AccordionProvider from "nq-component/dist/Accordion/AccordionProvider";

let timeoutId;

function Body({
  objects,
  fields,
  excludeFields,
  selected,
  selectable,
  onSelect,
  onClick,
  onLongPress,
  actionsList,
  collapsable,
  onCollapse,
  toggleable,
  actions,
  onClickItem,
  fieldImage,
  user, // New prop for user's role
}) {
  const onPointerDown = () => {
    timeoutId = setTimeout(onLongPress, 500); // set long press time to 500ms
  };

  const onPointerUp = () => {
    clearTimeout(timeoutId);
  };

  const getStatus = (status, role) => {
    if (role.roles[0].id === "MIS") {
      switch (status) {
        case "Dispatched":
          return "For CI";
        case "On going":
          return "For QA";
        case "Done":
          return "Done";
        default:
          return status;
      }
    } else if (role === "ENDORSER") {
      return status;
    }
  };

  return objects.map((object, index) => {
    const id = object.id || object._id;
    const checked = selected.includes(object);
    const status = getStatus(object.status, user); // Get the status based on the user's role

    return (
      <AccordionProvider key={id}>
        <tr>
          {selectable && (
            <th className="align-middle">
              <Checkbox
                checked={checked}
                id={"check_" + id}
                onChange={() => onSelect(index)}
              />
            </th>
          )}
          {Object.keys(fields).map((field, i) => {
            const options = fields[field];
            if (excludeFields.includes(field)) return null;
            return (
              <Accordion
                as="td"
                eventKey={`_${id}`}
                key={field}
                className="fs-sm text-truncate"
                onPointerDown={onPointerDown}
                onPointerUp={onPointerUp}
                style={{
                  cursor: "pointer",
                  maxWidth: "15em",
                }}
              >
                <OutputFactory field={field} object={object} {...options} />
              </Accordion>
            );
          })}
          <td className="fs-sm text-truncate">{status}</td>{" "}
          {/* Display the adjusted status */}
          {actionsList.length > 0 && (
            <td>
              <div style={{ width: "50px" }}></div>
            </td>
          )}
          {actionsList.map((action) => {
            return (
              <td className="text-truncate" key={action.label}>
                <button
                  onClick={action.onClick && action.onClick.bind(this, index)}
                  className="btn btn-outline-primary btn-sm fs-xs shadow-none"
                >
                  <i className={action.icon} /> {action.label}
                </button>
              </td>
            );
          })}
          <td>
            <div>{object?.roles?.[0]?.name}</div>
          </td>
        </tr>
        {collapsable && (
          <tr>
            <td className="p-0" colSpan={Object.keys(fields).length + 1}>
              <AccordionCollapse
                toggleable={toggleable}
                className="collapse p-3"
                eventKey={`_${id}`}
              >
                {onCollapse(index, object, excludeFields) ||
                  CollapseView(
                    index,
                    object,
                    excludeFields,
                    fields,
                    actions,
                    onClickItem,
                    fieldImage
                  )}
              </AccordionCollapse>
            </td>
          </tr>
        )}
      </AccordionProvider>
    );
  });
}

export default Body;
