import React from "react";
import MainPagePresenter from "./MainPagePresenter";
import { getAllSchemasUseCase } from "../../usecases/schema/usecases";
import { getCurrentUserUseCase, signOutUseCase } from "../../usecases/user";
import { Routes, Route } from "react-router-dom";
import { OffCanvas } from "nq-component";
import CollectionListPage from "../collection-list/CollectionListPage";
import CollectionFormPage from "../collection-form/CollectionFormPage";
import BasePage from "../../base/BasePage";
import NotFoundPage from "../notfound";
import { Layout, Progress, LogoHolder } from "nq-component";
import MigrationPage from "../migration/MigrationPage";
import AccountPage from "../account/AccountPage";
import RoleFormPage from "../role-form/RoleFormPage";
import withRouter from "../../withRouter";
import DashboardPage from "../dashboard/DashboardPage";
import HooksPage from "../web-hook/HooksPage";
import FunctionPage from "../web-hook/FunctionPage";
import SchemaPage from "../schema/SchemaPage";
import menus from "./menus.json";
import Menu from "../../components/Menu";
import UserPage from "../users/UserPage";
import EmploymentAuditPage from "../Audit/Employment/EmploymentAuditPage";
import ResidenceAuditPage from "../Audit/Residence/ResidenceAuditPage";
import ResidenceAuditFormPage from "../Audit/Residence/ResidenceAuditFormPage";
import BusinessAuditPage from "../Audit/Business/BusinessAuditPage";
import BusinessAuditFormPage from "../Audit/Business/BusinessAuditFormPage";
import EndorsementPage from "../endorsement/EndorsementPage";
import EndorsementFormPage from "../endorsement/EndorsementFormPage";
import UserFormPage from "../users/UserFormPage";
import DispatchPage from "../dispatch/DispatchPage";
import DispatchedPage from "../dispatch/DispatchedPage";
import EndorsementViewPage from "../endorsement/EndorsementViewPage";
import CreditInvestigatePage from "../agent/CreditInvestigatePage";
import CreditForm1Page from "../agent/CreditForm1Page";
import QualityAssurancePage from "../agent/QualityAssurancePage";

import ConstructionPage from "../ConstructionPage";
import QualityAssuranceQAPage from "../qa/QualityAssuranceQAPage";
import QAForm1Page from "../qa/QAForm1Page";
import DashboardAdminPage from "../dashboards/dashboard-admin/DashboardAdminPage";
import ReportManagementPage from "../report/ReportManagementPage";
import DashboardEndorserPage from "../dashboards/dashboard-endorser/DashboardEndorserPage";
import RankingPage from "../ranking/RankingPage";
import DashboardQAPage from "../dashboards/dashboard-qas/DashboardQAPage";
import DashboardAgentPage from "../dashboards/dashboard-agent/DashboardAgentPage";
import EmploymentAuditFormPage from "../Audit/Employment/EmploymentAuditFormPage";
import CreditForm2Page from "../agent/CreditForm2Page";
import QAForm2Page from "../qa/QAForm2Page";
import Report1 from "../../components/Reports/Report1";
import CreditForm3Page from "../agent/CreditForm3Page";
import QAForm3Page from "../qa/QAForm3Page";
import QAComparisonBVRPage from "../qa/comparison/bvr/QAComparisonBVRPage";
import QAComparisonEVRPage from "../qa/comparison/evr/QAComparisonEVRPage";
import QAComparisonRVRPage from "../qa/comparison/rvr/QAComparisonRVRPage";
import QAFormCIPage3 from "../agent/QAFormCIPage3";
import QAFormCIPage2 from "../agent/QAFormCIPage2";
import QAFormCIPage from "../agent/QAFormCIPage";

const circularImageStyle = {
  width: "90px", // Ensure width and height are equal
  height: "90px",
  borderRadius: "50%", // This makes the image circular
  objectFit: "cover", // This ensures the image covers the circle area
  marginTop: "20px",
};

class MainPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new MainPagePresenter(
      this,
      getCurrentUserUseCase(),
      signOutUseCase(),
      getAllSchemasUseCase()
    );
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  onClickSignOut() {
    this.presenter.onClickSignOut();
  }

  onClickMenu(e, item) {
    e.preventDefault();
    this.navigateTo(item.route);
  }

  filterAccess(menus, roles) {
    return menus.filter((menu) => {
      if (Array.isArray(menu.route)) {
        menu.route = this.filterAccess(menu.route, roles);
      }
      if (!menu.access) {
        return true;
      }
      if (roles.some((role) => menu.access.includes(role?.id))) {
        return true;
      }
      return false;
    });
  }

  render() {
    const user = this.getCurrentUser();
    const schemas = this.getSchemas();
    const roles = this.getCurrentRoles();
    if (user === undefined || schemas === undefined) {
      return <Progress />;
    }

    const menuss = menus;
    return (
      <Layout>
        <Layout.Context.Consumer>
          {(value) => (
            <OffCanvas onSetShow={value.setCollapse} show={value.collapsed}>
              <div
                className="offcanvas-body p-0"
                style={{ backgroundColor: "white" }}
              >
                <nav className="">
                  <div
                    className="text-center p-4"
                    style={{
                      backgroundImage: 'url("/nav-pic.jpg")',
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      height: "200px",
                    }}
                  >
                    <img
                      src={user.picture}
                      alt="Profile"
                      style={circularImageStyle}
                    />
                    {/* <LogoHolder
                      className="bg-white img-fluid"
                      textClassName="text-dark"
                      style={circularImageStyle}
                      logo={user.picture}
                      name={user.username}
                    /> */}
                    <p className="text-white mt-3">
                      {user.name || user.username}
                    </p>
                  </div>
                  <hr className="dropdown-divider bg-light" />
                  <div className="m-4">
                    <Menu
                      onClickItem={this.onClickMenu.bind(this)}
                      menus={this.filterAccess(menuss, roles)}
                    />
                  </div>
                </nav>
              </div>
              <div className="bg-white pb-3 ps-3">
                <button
                  className="nav-link text-white btn btn-link"
                  onClick={this.onClickSignOut.bind(this)}
                >
                  <i className="bi bi-power text-dark"></i>
                  <span className="ms-2 fw-bold small text-dark">Log out</span>
                </button>
              </div>
            </OffCanvas>
          )}
        </Layout.Context.Consumer>
        <main className="vh-100 d-flex flex-column">
          <Routes>
            <Route exact path={"/"} element={<DashboardPage />} />
            {/*<Route*/}
            {/*  exact*/}
            {/*  path={"/collection/dashboard"}*/}
            {/*  element={<DashboardPage />}*/}
            {/*/>*/}
            <Route
              exact
              path={"/collection/:name"}
              element={<CollectionListPage />}
            />
            <Route path={"/collection/roles/form"} element={<RoleFormPage />} />
            <Route
              path={"/collection/roles/form/:id"}
              element={<RoleFormPage />}
            />
            <Route
              path={"/collection/:name/form/"}
              element={<CollectionFormPage />}
            />
            <Route
              path={"/collection/:name/form/:id"}
              element={<CollectionFormPage />}
            />
            <Route path={"/migration"} element={<MigrationPage />} />
            <Route path={"/schema"} element={<SchemaPage />} />
            <Route path={"/account"} element={<AccountPage />} />
            <Route path={"/hooks"} element={<HooksPage />} />
            <Route path={"/function"} element={<FunctionPage />} />
            <Route path={"/users"} element={<UserPage />} />
            <Route
              path={"/employement-audit"}
              element={<EmploymentAuditPage />}
            />
            <Route path={"/residence-audit"} element={<ResidenceAuditPage />} />
            <Route
              path={"/residence-audit-form"}
              element={<ResidenceAuditFormPage />}
            />
            <Route
              path={"/residence-audit-form/:id"}
              element={<ResidenceAuditFormPage />}
            />
            <Route path={"/business-audit"} element={<BusinessAuditPage />} />
            <Route
              path={"/business-audit-form"}
              element={<BusinessAuditFormPage />}
            />
            <Route
              path={"/business-audit-form/:id"}
              element={<BusinessAuditFormPage />}
            />
            <Route path={"/endorsement"} element={<EndorsementPage />} />
            <Route
              path={"/endorsement-form"}
              element={<EndorsementFormPage />}
            />
            <Route
              path={"/endorsement-form/:id"}
              element={<EndorsementFormPage />}
            />
            <Route
              path={"/endorsement-form-view/:id"}
              element={<EndorsementViewPage />}
            />
            <Route path={"/users-form"} element={<UserFormPage />} />
            <Route path={"/users-form/:id"} element={<UserFormPage />} />
            <Route path={"/dispatch-page"} element={<DispatchPage />} />
            <Route path={"/dispatched-page"} element={<DispatchedPage />} />
            <Route
              path={"/credit-investigate"}
              element={<CreditInvestigatePage />}
            />
            <Route
              path={"/audit-form/:id/:id/:id"}
              element={<CreditForm1Page />}
            />
            <Route
              path={"/audit-form-e/:id/:id/:id"}
              element={<CreditForm2Page />}
            />
            <Route
              path={"/audit-form-r/:id/:id/:id"}
              element={<CreditForm3Page />}
            />
            <Route
              path={"/audit-form-ci-bvr/:id/:id/:id/:formId"}
              element={<QAFormCIPage />}
            />
            <Route
              path={"/audit-form-ci-rvr/:id/:id/:id/:formId"}
              element={<QAFormCIPage3 />}
            />
            <Route
              path={"/audit-form-ci-evr/:id/:id/:id/:formId"}
              element={<QAFormCIPage2 />}
            />
            <Route
              path={"/audit-form-qa/:id/:id/:id/:formId"}
              element={<QAForm1Page />}
            />
            <Route
              path={"/audit-form-qa-2/:id/:id/:id/:formId"}
              element={<QAForm2Page />}
            />
            <Route
              path={"/audit-form-qa-3/:id/:id/:id/:formId"}
              element={<QAForm3Page />}
            />
            <Route
              path={"/quality-assurance-ci"}
              element={<QualityAssurancePage />}
            />
            <Route
              path={"/quality-assurance-qa"}
              element={<QualityAssuranceQAPage />}
            />
            <Route path={"/underConstruction"} element={<ConstructionPage />} />
            <Route path={"/dashboard-admin"} element={<DashboardAdminPage />} />
            <Route path={"/admin-report"} element={<ReportManagementPage />} />
            <Route
              path={"/dashboard-endorser"}
              element={<DashboardEndorserPage />}
            />
            <Route path={"/ranking-ci"} element={<RankingPage />} />
            <Route path={"/dashboard-QA"} element={<DashboardQAPage />} />
            <Route path={"/dashboard-agent"} element={<DashboardAgentPage />} />
            <Route
              path={"/employment-audit-form"}
              element={<EmploymentAuditFormPage />}
            />
            <Route
              path={"/employment-audit-form/:id"}
              element={<EmploymentAuditFormPage />}
            />
            <Route path={"/report1"} element={<Report1 />} />
            <Route
              path={"/comparison-bvr/:id/:id/:id/:formId"}
              element={<QAComparisonBVRPage />}
            />
            <Route
              path={"/comparison-evr/:id/:id/:id/:formId"}
              element={<QAComparisonEVRPage />}
            />
            <Route
              path={"/comparison-rvr/:id/:id/:id/:formId"}
              element={<QAComparisonRVRPage />}
            />
            <Route element={<NotFoundPage />} />
          </Routes>
        </main>
      </Layout>
    );
  }
}

export default withRouter(MainPage);
