import React from "react";
import withRouter from "../../withRouter";
import BaseListPage from "../../base/BaseListPage";
import { countObjectUseCase, findObjectUseCase } from "../../usecases/object";
import NavBar from "../../components/navbar";
import { Button, InfiniteScroll, Progress, dialog } from "nq-component";
import InputFactory from "../../components/InputFactory";
import Search from "../../components/Search";
import Table from "../../components/Table";
import RankingPresenter from "./RankingPresenter";
import TableRanking from "../../components/TableRanking";
import moment from "moment";

const style = {
  backgroundColor: "blue",
};

class RankingPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new RankingPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase()
    );

    this.state = {
      ...this.state,
      startDate: "",
      endDate: "",
      filteredObjects: [],
    };
  }

  getCollectionName() {
    return "users";
  }

  onStartDateChange = (event) => {
    this.setState({ startDate: event.target.value }, this.filterByDateRange);
  };

  onEndDateChange = (event) => {
    this.setState({ endDate: event.target.value }, this.filterByDateRange);
  };

  filterByDateRange = () => {
    const { startDate, endDate, objects } = this.state;
    if (startDate && endDate) {
      const start = moment(startDate);
      const end = moment(endDate);
      const filteredObjects = objects.map((object) => {
        const filteredCountPoints = object.countPoints.filter((point) => {
          const createdAt = moment(point.createdAt);
          return createdAt.isBetween(start, end, "days", "[]");
        });
        return { ...object, countPoints: filteredCountPoints };
      });

      // Update the state with filtered objects
      this.setState({ filteredObjects });
    }
  };

  onClickItemDelete(index, object) {
    let roleName = "";
    switch (object.roles[0].id) {
      case "AGENTS":
        roleName = "Credit Investigator";
        break;
      case "QA":
        roleName = "Quality Assurance";
        break;
      case "ENDORSER":
        roleName = "ENDORSER";
        break;
      case "MIS":
        roleName = "MIS";
        break;
      default:
        break;
    }

    dialog.fire({
      html: (
        <>
          <div className="">
            <div>
              <h5 className="text-danger">Delete</h5>
            </div>
            <div className="border p-2 text-center">
              {object.firstName} {object.middleName} {object.lastName}
              <span style={{ color: "#004e9b" }}> ({roleName})</span>
            </div>
            <div className="mt-4">
              <p className="mb-0">Are you sure you want to delete this user?</p>
              <p className="text-danger">This action cannot be undone.</p>
            </div>
            <div className="d-flex justify-content-center text-center mb-3">
              <button
                className="btn me-2"
                onClick={() => dialog.close()}
                style={{ backgroundColor: "#e6e6e6" }}
              >
                Cancel
              </button>
              <button
                className="btn text-white"
                style={{ backgroundColor: "#bb1c1c" }}
                onClick={() => this.presenter.onClickItemDelete(index)}
              >
                Delete
              </button>
            </div>
          </div>
        </>
      ),
      footer: false,
    });
  }

  onClickStatus(index) {
    this.presenter.onClickStatus(index);
  }

  onCollapse(index, object) {
    return (
      <>
        <div className="d-flex">
          {object.picture && (
            <div
              className="mb-3"
              style={{
                width: "100px",
                height: "100px",
              }}
            >
              <img
                className="w-100 h-100"
                style={{ objectFit: "cover" }}
                src={object.picture}
              />
            </div>
          )}
          <ul className="list-unstyled ms-1">
            <li>
              <span className="ms-2 fw-light">Name:</span>
              <span className="fs-sm text-nowrap">{object.firstName}</span>
            </li>
            <li>
              <span className="ms-2 fw-light">Email:</span>
              <span className="fs-sm text-nowrap">{object.email}</span>
            </li>
            <li>
              <span className="ms-2 fw-light">Role:</span>
              <span className="fs-sm text-nowrap">{object.roles[0].id}</span>
            </li>
            <li>
              <span className="ms-2 fw-light">statuses:</span>
              <span className="fs-sm text-nowrap">{object.status}</span>
            </li>
          </ul>
        </div>

        <div className="d-flex">
          <div className="me-1">
            <button
              className="btn"
              onClick={() => this.onClickItem(index)}
              style={{ backgroundColor: "#fdcb5b" }}
            >
              <i className="bi bi-pencil-square me-1"></i>EDIT
            </button>
          </div>
          <div className="me-1">
            <button
              className="btn text-white"
              onClick={() => this.onClickStatus(index)}
              style={{ backgroundColor: "#004e9b" }}
            >
              <i className="bi bi-pencil-square me-1"></i>
              {object.status}
            </button>
          </div>
          <div className="me-1">
            <button
              className="btn btn-danger"
              onClick={() => this.onClickItemDelete(index, object)}
            >
              <i className="bi bi-trash me-1"></i>DELETE
            </button>
          </div>
          <div className="">
            {object.statuses === "Approved" && (
              <button
                className="btn text-white"
                onClick={() => this.onClickItemInvoice(index, object)}
                style={{ backgroundColor: "#ebbf38" }}
              >
                <i className="bi bi-plus me-1"></i>CREATE INVOICE
              </button>
            )}
          </div>
        </div>
      </>
    );
  }

  onClickAdd() {
    this.presenter.onClickAdd();
  }

  filterSubmit(where) {
    this.presenter.filterSubmit(where);
  }

  onChangeFilter(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }
    this.filterSubmit(where);
  }

  render() {
    const schema = this.getSchema(this.getCollectionName());
    const {
      objects,
      selected,
      count,
      progress,
      startDate,
      endDate,
      filteredObjects,
    } = this.state;

    if (!schema) return <Progress />;
    const user = this.getCurrentUser();
    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold text-capitalize">
                  {schema.label || "CI Ranking"}
                </h1>
                {selected.length > 0 ? (
                  <div>
                    <span className="ms-2">Selected: </span>
                    <span className="fs-sm text-nowrap">{selected.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                ) : (
                  <div>
                    <span className="ms-2">Total: </span>
                    <span className="fs-sm text-nowrap">{objects.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                )}
              </div>

              <div className="d-flex mt-3">
                {Object.keys(schema.filters || {}).map((field) => {
                  let { type, ...options } = schema.filters[field];
                  return (
                    <InputFactory
                      key={field}
                      className="ms-1"
                      type={type}
                      field={field}
                      where={{}}
                      onChange={this.onChangeFilter.bind(this, type)}
                      {...options}
                    />
                  );
                })}

                <div className="d-flex ms-3">
                  <input
                    type="date"
                    className="form-control form-control-md shadow-none w-auto rounded-0 border-end-0 rounded-start"
                    value={startDate}
                    onChange={this.onStartDateChange}
                  />

                  <input
                    type="date"
                    className="form-control form-control-md shadow-none w-auto rounded-0 rounded-end"
                    value={endDate}
                    onChange={this.onEndDateChange}
                  />
                </div>
              </div>

              <TableRanking
                fields={schema.fields}
                objects={filteredObjects.length ? filteredObjects : objects}
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  [
                    "acl",
                    "password",
                    "username",
                    "firstName",
                    "middleName",
                    "lastName",
                    "picture",
                    "signature",
                    "email",
                    "id",
                    "rolesName",
                    "locationP",
                    "status",
                    "roles",
                    "ROLES",
                    "roleName",
                    "numberEndorsement",
                    "rank",
                    "selectedClient",
                    "group",
                    "branchName",
                  ]
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                className="mt-3"
                style={style}
              />
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default withRouter(RankingPage);
