import { File } from "nq";
import { Checkbox } from "nq-component";
import React from "react";

// Define styles object
const styles = {
  mainContainer: {
    // width: "209mm",
    // height: "370mm",
    width: "209mm",
    height: "296mm",
    fontFamily: "Arial, sans-serif",
    padding: 0,
    margin: 0,
    backgroundColor: "white",
    fontSize: "10px",
    // position: "absolute",
  },
  backgroundImg: {
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${require("../../../components/image/rp1.jpeg")})`,
    // width: "290mm",
    // height: "290mm",
    width: "209mm",
    height: "296mm",
    marginTop: "-10rem",
    // border: "1px solid black",
  },
  checkboxContainer: {
    width: "28px",
    height: "20px",
    left: "3.7rem",
    position: "absolute",
    backgroundColor: "#000",
  },
  checkboxContainerStatus: {
    width: "23px",
    height: "15px",
    position: "absolute",
    backgroundColor: "#000",
  },
  remarks: {
    position: "absolute",
    left: "26.5rem",
    top: "10rem",
    height: "45rem",
    padding: "5px",
    width: "18rem",
    display: "flex",
    fontSize: "7.5px",
  },
  remarks2: {
    position: "absolute",
    left: "26rem",
    top: "5.5rem",
    height: "43rem",
    padding: "1rem",
    width: "20.5rem",
    display: "flex",
    fontSize: "7px",
  },

  pageLength: {
    top: "130rem",
    // height: "48rem",
    // width: "68rem",
    height: "100%",
    width: "100%",
  },
  signatureAlignment: {
    display: "flex",
    paddingLeft: "2rem",
    alignItems: "baseline",
  },
};
const chunkArray = (array, chunkSize) => {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
};

const imageLayout = (images) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "1rem",
      }}
    >
      {images.map((imageObj, index) => (
        <div
          key={index}
          style={{
            display: "grid",
            width: "45%",
            justifyContent: "center",
            marginBottom: "2rem",
          }}
        >
          <label style={{ textAlign: "center" }}>{imageObj.label}</label>
          <div>
            <img
              src={File.get(
                `${imageObj.image}?applicationId=77c55e9e-2e95-41da-92f1-ccc177ccc59c`
              )}
              style={{
                width: "300px",
                height: "300px",
                objectFit: "cover",
              }}
              alt={`images-${index}`}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

function Report3({ objects, report, user }) {
  console.log("report show", report);
  // const categories = report[0]?.categories || [];
  // const half = Math.ceil(categories.length / 2);
  const object = report[0];

  // const formattedRemarks = object?.remarks
  //   .replace("*REMARKS: VERIFIED", "<strong>*REMARKS: VERIFIED</strong>")
  //   .replace(/\*/g, "<br>*");
  const formattedRemarks = object?.remarks
    .replace("*All information", "<strong>*All information</strong>") // Example to bold specific parts
    .replace("*Premises checked", "<strong>*Premises checked</strong>") // Bold specific parts
    .replace(/\*/g, "<br>*") // Replace * with a line break before each * to maintain structure
    // .replace(/__/g, "_____________") // Placeholder for underlines
    .replace(/\n/g, "<br>"); // Replace new lines with <br> tags

  const formattedRemarks2 = object?.remarks2
    .replace("Informant 1", "<strong>Informant 1</strong>") // Example to bold specific parts
    .replace("Informant 2", "<strong>Informant 2</strong>") // Bold specific parts
    .replace(
      "Barangay Checking/Homeowners Association",
      "<strong>Barangay Checking/Homeowners Association</strong>"
    ) // Bold specific parts
    .replace(/\*/g, "<br>*") // Replace * with a line break before each * to maintain structure
    // .replace(/__/g, "_____________") // Placeholder for underlines
    .replace(/\n/g, "<br>"); // Replace new lines with <br> tags

  const checkBoxNeigh = () => {
    const checkBox = object?.answers[26]?.answer;
    // console.log("checked: ", checkBox);
    switch (checkBox) {
      case "MIXED":
        return (
          <div
            style={{
              ...styles.checkboxContainerStatus,
              top: "88.2rem",
              left: "2.4rem",
            }}
          />
        );
      case "COMMERCIAL":
        return (
          <div
            style={{
              ...styles.checkboxContainerStatus,
              top: "88.2rem",
              left: "8.8rem",
            }}
          />
        );
      case "RESIDENTIAL":
        return (
          <div
            style={{
              ...styles.checkboxContainerStatus,
              top: "88.2rem",
              left: "14.2rem",
            }}
          />
        );
      case "HOME ADDRESS":
        return (
          <div
            style={{
              ...styles.checkboxContainerStatus,
              top: "89.2rem",
              left: "2.4rem",
            }}
          />
        );
      default:
        return null;
    }
  };

  const checkBoxClass = () => {
    const checkBox = object?.answers[28]?.answer;
    // console.log("checked: ", checkBox);
    switch (checkBox) {
      case "UPPER- MIDDLE CLASS":
        return (
          <div
            style={{
              ...styles.checkboxContainerStatus,
              top: "93.9rem",
              // height: "22px",
              left: "2.4rem",
            }}
          />
        );
      case "MIDDLE-LOW MIDDLE CLASS":
        return (
          <div
            style={{
              ...styles.checkboxContainerStatus,
              top: "94.8rem",
              // height: "22px",
              left: "2.4rem",
            }}
          />
        );
      case "LOWER CLASS":
        return (
          <div
            style={{
              ...styles.checkboxContainerStatus,
              top: "95.7rem",
              // height: "22px",
              left: "2.4rem",
            }}
          />
        );
      case "GREY CONCERN":
        return (
          <div
            style={{
              ...styles.checkboxContainerStatus,
              top: "96.6rem",
              // height: "22px",
              left: "2.4rem",
            }}
          />
        );
      default:
        return <div></div>;
    }
  };

  const imageChunks = chunkArray(object.uploadRelation, 6);

  const checkBoxHome = () => {
    const checkBox = object?.answers[29]?.answer;
    // console.log("checked: ", checkBox);
    switch (checkBox) {
      case "OWNED":
        return (
          <div
            style={{
              ...styles.checkboxContainerStatus,
              top: "100.2rem",
              left: "3.7rem",
              // height: "22px",
            }}
          />
        );
      case "LIVING WITH RELATIVES OR OTHERS":
        return (
          <div
            style={{
              ...styles.checkboxContainerStatus,
              top: "101.1rem",
              left: "3.7rem",
              // height: "22px",
            }}
          />
        );
      case "RENTED":
        return (
          <div
            style={{
              ...styles.checkboxContainerStatus,
              top: "102rem",
              left: "3.7rem",
              // height: "22px",
            }}
          />
        );
      case "MORTGAGED":
        return (
          <div
            style={{
              ...styles.checkboxContainerStatus,
              top: "103.8rem",
              left: "3.7rem",
              // height: "22px",
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div style={{ ...styles.mainContainer, marginBottom: "10rem" }}>
        <div style={{ ...styles.backgroundImg }}>
          <p
            style={{
              top: "7rem",
              left: "8rem",
              position: "absolute",
            }}
          >
            {object?.accountName}
          </p>
          <p
            style={{
              top: "11rem",
              left: "12rem",
              position: "absolute",
            }}
          >
            {object?.answers[0]?.answer}
          </p>
          <p
            style={{
              top: "12rem",
              left: "12rem",
              position: "absolute",
            }}
          >
            {object?.answers[1]?.answer}
          </p>
          <p
            style={{
              top: "13rem",
              left: "12rem",
              position: "absolute",
            }}
          >
            {object?.answers[2]?.answer}
          </p>
          <p
            style={{
              top: "13.9rem",
              left: "12rem",
              position: "absolute",
            }}
          >
            {object?.answers[3]?.answer}
          </p>
          <p
            style={{
              top: "14.5rem",
              left: "12rem",
              position: "absolute",
            }}
          >
            {object?.answers[4]?.answer}
          </p>
          <p
            style={{
              top: "15.5rem",
              left: "12rem",
              position: "absolute",
            }}
          >
            {object?.answers[5]?.answer}
          </p>

          <p
            style={{
              top: "17.1rem",
              left: "12rem",
              position: "absolute",
            }}
          >
            {object?.answers[6]?.answer}
          </p>
          <p
            style={{
              top: "19.6rem",
              left: "12rem",
              position: "absolute",
            }}
          >
            {object?.answers[7]?.answer}
          </p>
          <p
            style={{
              top: "20.5rem",
              left: "12rem",
              position: "absolute",
            }}
          >
            {object?.answers[8]?.answer}
          </p>
          <p
            style={{
              top: "21.4rem",
              left: "12rem",
              position: "absolute",
            }}
          >
            {object?.answers[9]?.answer}
          </p>
          <p
            style={{
              top: "22.5rem",
              left: "12rem",
              position: "absolute",
            }}
          >
            {object?.answers[10]?.answer}
          </p>
          <p
            style={{
              top: "26.8rem",
              left: "12rem",
              position: "absolute",
            }}
          >
            {object?.answers[11]?.answer}
          </p>
          <p
            style={{
              top: "27.8rem",
              left: "12rem",
              position: "absolute",
            }}
          >
            {object?.answers[12]?.answer}
          </p>
          <p
            style={{
              top: "28.8rem",
              left: "12rem",
              position: "absolute",
            }}
          >
            {object?.answers[13]?.answer}
          </p>
          <p
            style={{
              top: "29.7rem",
              left: "12rem",
              position: "absolute",
            }}
          >
            {object?.answers[14]?.answer}
          </p>
          <p
            style={{
              top: "30.4rem",
              left: "12rem",
              position: "absolute",
            }}
          >
            {object?.answers[15]?.answer}
          </p>
          <p
            style={{
              top: "31.3rem",
              left: "12rem",
              position: "absolute",
            }}
          >
            {object?.answers[16]?.answer}
          </p>
          <p
            style={{
              top: "34.8rem",
              left: "12rem",
              position: "absolute",
            }}
          >
            {object?.answers[17]?.answer}
          </p>
          <p
            style={{
              top: "35.7rem",
              left: "12rem",
              position: "absolute",
            }}
          >
            {object?.answers[18]?.answer}
          </p>
          <p
            style={{
              top: "36.6rem",
              left: "12rem",
              position: "absolute",
            }}
          >
            {object?.answers[19]?.answer}
          </p>
          <p
            style={{
              top: "37.7rem",
              left: "12rem",
              position: "absolute",
              fontSize: "6px",
            }}
          >
            {object?.answers[20]?.answer}
          </p>
          <p
            style={{
              top: "39.3rem",
              left: "12rem",
              position: "absolute",
            }}
          >
            {object?.answers[21]?.answer}
          </p>
          <p
            style={{
              top: "40.2rem",
              left: "12rem",
              position: "absolute",
            }}
          >
            {object?.answers[22]?.answer}
          </p>
          <p
            style={{
              top: "41.1rem",
              left: "12rem",
              position: "absolute",
            }}
          >
            {object?.answers[23]?.answer}
          </p>
          <p
            style={{
              top: "42rem",
              left: "12rem",
              position: "absolute",
              fontSize: "6px",
            }}
          >
            {object?.answers[24]?.answer}
          </p>
          <div style={styles.remarks}>
            {/* <span>{object?.remarks}</span> */}
            <div dangerouslySetInnerHTML={{ __html: formattedRemarks }} />
          </div>
        </div>
      </div>
      <div style={{ ...styles.mainContainer }}>
        <div
          style={{
            ...styles.backgroundImg,
            backgroundImage: `url(${require("../../../components/image/rp2.jpeg")})`,
          }}
        >
          <p
            style={{
              top: "80.5rem",
              left: "8.5rem",
              position: "absolute",
            }}
          >
            {object?.accountName}
          </p>
          <p
            style={{
              top: "85rem",
              left: "12rem",
              position: "absolute",
            }}
          >
            {object?.answers[25]?.answer}
          </p>
          {checkBoxNeigh()}

          <p
            style={{
              top: "89rem",
              left: "11rem",
              width: "13rem",
              height: "2rem",
              position: "absolute",
            }}
          >
            {object?.answers[27]?.answer}
          </p>

          {checkBoxClass()}
          {checkBoxHome()}
          <p
            style={{
              top:
                object?.answers[29]?.answer === "OWNED"
                  ? "106.3rem"
                  : object?.answers[29]?.answer ===
                    "LIVING WITH RELATIVES OR OTHERS"
                  ? "101rem"
                  : object?.answers[29]?.answer === "RENTED"
                  ? "102rem"
                  : object?.answers[29]?.answer === "MORTGAGED"
                  ? "103.9rem"
                  : "0px",
              left:
                object?.answers[29]?.answer === "OWNED"
                  ? "11.2rem"
                  : object?.answers[29]?.answer ===
                    "LIVING WITH RELATIVES OR OTHERS"
                  ? "18.2rem"
                  : object?.answers[29]?.answer === "RENTED"
                  ? "18.2rem"
                  : object?.answers[29]?.answer === "MORTGAGED"
                  ? "18.2rem"
                  : "0px",
              width: "20rem",
              height: "2rem",
              position: "absolute",
            }}
          >
            {object?.answers[30]?.answer}
          </p>
          <p
            style={{
              top:
                object?.answers[29]?.answer === "OWNED"
                  ? "107.2rem"
                  : object?.answers[29]?.answer ===
                    "LIVING WITH RELATIVES OR OTHERS"
                  ? "106.3rem"
                  : object?.answers[29]?.answer === "RENTED"
                  ? "102.9rem"
                  : object?.answers[29]?.answer === "MORTGAGED"
                  ? "106.3rem"
                  : "0px",
              left:
                object?.answers[29]?.answer === "OWNED"
                  ? "11.2rem"
                  : object?.answers[29]?.answer ===
                    "LIVING WITH RELATIVES OR OTHERS"
                  ? "11.2rem"
                  : object?.answers[29]?.answer === "RENTED"
                  ? "18.2rem"
                  : object?.answers[29]?.answer === "MORTGAGED"
                  ? "11.2rem"
                  : "0px",
              width: "20rem",
              height: "2rem",
              position: "absolute",
            }}
          >
            {object?.answers[31]?.answer}
          </p>
          <p
            style={{
              top:
                object?.answers[29]?.answer === "OWNED"
                  ? "108rem"
                  : object?.answers[29]?.answer ===
                    "LIVING WITH RELATIVES OR OTHERS"
                  ? "107rem"
                  : object?.answers[29]?.answer === "RENTED"
                  ? "106.3rem"
                  : object?.answers[29]?.answer === "MORTGAGED"
                  ? "107.1rem"
                  : "0px",
              left: "11.2rem",
              // width: "20rem",
              // height: "2rem",
              position: "absolute",
            }}
          >
            {object?.answers[32]?.answer}
          </p>
          <p
            style={{
              top:
                object?.answers[29]?.answer === "OWNED"
                  ? "108rem"
                  : object?.answers[29]?.answer ===
                    "LIVING WITH RELATIVES OR OTHERS"
                  ? "107.9rem"
                  : object?.answers[29]?.answer === "RENTED"
                  ? "107.1rem"
                  : object?.answers[29]?.answer === "MORTGAGED"
                  ? "108rem"
                  : "0px",
              left: "11.2rem",
              // width: "20rem",
              // height: "2rem",
              position: "absolute",
            }}
          >
            {object?.answers[33]?.answer}
          </p>
          <p
            style={{
              top: "107.9rem",
              left: "11.2rem",
              // width: "20rem",
              // height: "2rem",
              position: "absolute",
            }}
          >
            {object?.answers[34]?.answer}
          </p>
          <div
            style={{
              ...styles.remarks2,
              top: "83rem",
              height: "25rem",
            }}
          >
            {/* <span>{object?.remarks2}</span> */}
            <div dangerouslySetInnerHTML={{ __html: formattedRemarks2 }} />
          </div>
          <div
            style={{
              top: "110.9rem",
              left: "4.2rem",
              // width: "20rem",
              // height: "2rem",
              position: "absolute",
            }}
          >
            <div style={styles.signatureAlignment}>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "center",
                }}
              >
                <label style={{ fontSize: "12px" }}>CREDIT INVESTIGATOR </label>
                <div
                  className="text-center"
                  style={{ borderBottom: "2px solid", width: "15rem" }}
                >
                  <img
                    // src={objects?.agentSignature}
                    src={File.get(
                      `${objects?.agentSignature}?applicationId=77c55e9e-2e95-41da-92f1-ccc177ccc59c`
                    )}
                    height={100}
                    width={300}
                    alt="Signature"
                  />
                  <p className="text-center">{objects?.agent}</p>
                </div>
              </div>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "center",
                  marginLeft: "35px",
                }}
              >
                <div>
                  {" "}
                  <label style={{ fontSize: "12px" }}>Date: </label>
                </div>

                <div
                  style={{
                    borderBottom: "2px solid",
                    width: "15rem",
                    // marginLeft: "30px",
                  }}
                >
                  {objects?.dateTimeSubmitted}
                </div>
              </div>
            </div>
            <div style={styles.signatureAlignment}>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "center",
                }}
              >
                <label style={{ fontSize: "12px" }}>REVIEWED BY: </label>
                <div
                  className="text-center"
                  style={{ borderBottom: "2px solid", width: "15rem" }}
                >
                  <img
                    // src={objects?.agentSignature}
                    src={File.get(
                      `${objects?.qaSignature}?applicationId=77c55e9e-2e95-41da-92f1-ccc177ccc59c`
                    )}
                    height={100}
                    width={300}
                    alt="Signature"
                  />
                  <p>{objects?.qaOfficer}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div style={{ ...styles.pageLength }}>
          <div style={styles.signatureAlignment}>
            <div
              style={{
                width: "50%",
                display: "flex",
                alignItems: "baseline",
                justifyContent: "center",
              }}
            >
              <label style={{ fontSize: "12px" }}>CREDIT INVESTIGATOR </label>
              <div style={{ borderBottom: "2px solid", width: "15rem" }}>
                <img
                  src={objects?.agentSignature}
                  height={100}
                  width={300}
                  alt="Signature"
                />
              </div>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                alignItems: "baseline",
                justifyContent: "center",
              }}
            >
              <label style={{ fontSize: "12px" }}>Date: </label>
              <div style={{ borderBottom: "2px solid", width: "15rem" }}>
                {objects?.dateTimeSubmitted}
              </div>
            </div>
          </div>
          <div style={styles.signatureAlignment}>
            <div
              style={{
                width: "50%",
                display: "flex",
                alignItems: "baseline",
                justifyContent: "center",
              }}
            >
              <label style={{ fontSize: "12px" }}>REVIEWED BY: </label>
              <div style={{ borderBottom: "2px solid", width: "15rem" }}>
                <img
                  src={objects?.agentSignature}
                  height={100}
                  width={300}
                  alt="Signature"
                />
              </div>
            </div>
          </div>
        </div> */}
      </div>

      {imageChunks.map((chunk, index) => (
        <div
          key={index}
          style={{
            ...styles.mainContainer,
            marginTop: index === 0 ? "190px" : "0px",
          }}
        >
          <div style={{ marginTop: "30px" }}> {imageLayout(chunk)}</div>
        </div>
      ))}

      {/* <div
        style={{ ...styles.mainContainer, top: "95rem", marginTop: "15rem" }}
      >
        <div style={styles.pageLength}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            {object?.uploadRelation.map((img, index) => (
              <div
                key={index}
                style={{
                  display: "grid",
                  width: "45%", // Adjust this as needed
                  justifyContent: "center",
                  marginBottom: "2rem",
                }}
              >
                <img
                  // src={img.image}
                  src={File.get(
                    `${img.image}?applicationId=77c55e9e-2e95-41da-92f1-ccc177ccc59c`
                  )}
                  alt="img"
                  style={{
                    width: "300px", // Set a fixed width
                    height: "300px", // Set a fixed height
                    objectFit: "cover", // Ensures the image fits within the box
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div> */}
    </>
  );
}
export default Report3;
